<template>
    <div style="height: 100%;">
        <div style="background:#fff;border-radius:5px;padding:20px;overflow:hidden;height: 98%;">
            <div style="width:48%;float:left;margin-right:2%;height: 100%;">
                <div style="box-shadow: 0px 0px 5px #ddd;border-radius:10px;padding:20px;height: 20%;">
                    <div style="margin-bottom:10px;">
                        <img src="../../../assets/images/count.png" alt="" style="width:15px;">
                        <p style="display: inline-block;margin-left:10px">数据统计</p>
                    </div>
                    <div style="display: flex">
                        <div style="flex:1;margin:1%;position:relative;">
                            <img src="../../../assets/images/hotelCount.png" alt="" style="width:100%;" >
                            <div style="color:#fff;position:absolute;top:10%;left:8%;font-size: 12px;">
                                <p style="font-size:32px;">{{hotelCount.count}}</p>
                                <p>项目总数</p>
                                <p>比上个月增加{{hotelCount.clm}}%</p>
                            </div>
                        </div>
                        <div style="flex:1;margin:1%;position:relative;">
                            <img src="../../../assets/images/roomCount.png" alt="" style="width:100%;">
                            <div style="color:#fff;position:absolute;top:10%;left:8%;font-size: 12px;">
                                <p style="font-size:32px;">{{roomCount.count}}</p>
                                <p>房间总数</p>
                                <p>比上个月增加{{roomCount.clm}}%</p>
                            </div>
                        </div>
                        <div style="flex:1;margin:1%;position:relative;">
                            <img src="../../../assets/images/deviceCount.png" alt="" style="width:100%;">
                            <div style="color:#fff;position:absolute;top:10%;left:8%;font-size: 12px;">
                                <p style="font-size:32px;">{{deviceCount.count}}</p>
                                <p>设备总数</p>
                                <p>比上个月增加{{deviceCount.clm}}%</p>
                            </div>
                        </div>
                        <div style="flex:1;margin:1%;position:relative;">
                            <img src="../../../assets/images/online.png" alt="" style="width:100%;">
                            <div style="color:#fff;position:absolute;top:10%;left:8%;font-size: 12px;">
                                <p style="font-size:32px;">{{onlineCount.count}}</p>
                                <p>在线设备总数</p>
                            </div>
                        </div>
                        <div style="flex:1;margin:1%;position:relative;">
                            <img src="../../../assets/images/outline.png" alt="" style="width:100%;">
                            <div style="color:#fff;position:absolute;top:10%;left:8%;font-size: 12px;">
                                <p style="font-size:32px;">{{outlineCount.count}}</p>
                                <p>离线设备总数</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="box-shadow: 0px 0px 5px #ddd;border-radius:10px;padding:20px;margin-top:3%;height: 66%;">
                    <div style="margin-bottom:10px;">
                        <img src="../../../assets/images/count.png" alt="" style="width:15px;">
                        <p style="display: inline-block;margin-left:10px">{{chartHotelName}}设备日活量统计</p>
                        <div id="main" style="width: 100%;height: 400px;"></div>
                    </div>
                </div>
            </div>
            <div style="width:50%;display: inline-block;height: 100%;">
                <div style="position:relative;box-shadow: 0px 0px 5px #ddd;border-radius:10px;padding:10px 20px;height: 45%;">
                    <div style="margin-bottom:5px;">
                        <img src="../../../assets/images/count.png" alt="" style="width:15px;">
                        <p style="display: inline-block;margin-left:10px">设备活跃度查询</p>
                    </div>
                    <div style="color:#409EFF">
                        项目名称：<el-input size="medium" placeholder="请输入内容" v-model="smartHomeName"></el-input>
                        日期范围：<el-date-picker v-model="dateScope" value-format="yyyy-MM-dd" type="daterange" align="right"
                                unlink-panels
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期"
                                :picker-options="pickerOptions" @change="selDate()">
                        </el-date-picker>
                        <el-button type="primary" style="margin-left:5px;padding:10px 15px; " @click="hotelActivity">查询</el-button>
                    </div>
                    <div class="wrapper">
                        <el-table :data="hotelActivityCount" style="width: 100%;cursor : pointer;" @row-click="selHotel" >
                            <el-table-column type="index" width="100" header-align="center" align="center" ></el-table-column>
                            <el-table-column prop="smartHomeName" label="项目名称" header-align="center" align="center" ></el-table-column>
                            <el-table-column prop="count" label="操作次数" header-align="center" align="center" ></el-table-column>
                        </el-table>
                    </div>
                    <!--分页-->
                    <Mypage :total="total" :pageSize="limit" @changePage="changePage"/>
                </div>
                <div style="position:relative;box-shadow: 0px 0px 5px #ddd;border-radius:10px;padding:20px;height: 44%;margin-top:3%">
                    <div style="margin-bottom:10px;">
                        <img src="../../../assets/images/count.png" alt="" style="width:15px;">
                        <p style="display: inline-block;margin-left:10px">{{chartHotelName}}单设备活跃度查询</p>
                    </div>
                    <div class="wrapper">
                        <el-table :data="roomActivityData" style="width: 100%;cursor : pointer;">
                            <el-table-column type="index"  header-align="center" align="center" ></el-table-column>
                            <el-table-column prop="roomName" label="设备名称"  header-align="center" align="center" ></el-table-column>
                            <el-table-column prop="count" label="操作次数"  header-align="center" align="center" ></el-table-column>
                            <el-table-column prop="deviceStatus" label="当前状态"  header-align="center" align="center" >
                                <template scope="scope">
                                    <span style="border:1px solid #666;color:#666;background:#ddd;padding: 0 5px;border-radius: 3px;"  v-if="scope.row.deviceStatus===0">离线</span>
                                    <span style="border:1px solid #276EF1;color:#276EF1;background:#E1EBFF;padding: 0 5px;border-radius: 3px;" v-else-if="scope.row.deviceStatus===1">在线</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!--分页-->
                    <Mypage :total="roomTotal" :pageSize="limit" @changePage="changePage"/>
                </div>
            </div>
        </div>
        <!--详情弹框-->
        <el-dialog title="房间信息" :visible.sync="detailVisible" width="50%" center  :before-close="handleClose">
            <div style="overflow:hidden;">
                <div class="detailRoomCont"><span style="color:#409EFF">房间名称: </span>{{detailData.roomName}}</div>
                <div class="detailRoomCont"><span style="color:#409EFF">房间号: </span>{{detailData.roomNumber}}</div>
                <div class="detailRoomCont"><span style="color:#409EFF">房间模板: </span>{{detailData.templateName}}</div>
                <div class="detailRoomCont"><span style="color:#409EFF">房间状态:</span>
                    <span v-if="detailData.status===0">未配置</span>
                    <span v-else-if="detailData.status===1">配置中</span>
                    <span v-else-if="detailData.status===2">配置完成</span>
                </div>
                <div class="detailRoomCont"><span style="color:#409EFF">房间属性:</span>
                    <span v-if="detailData.roomType===0">样板房间</span>
                    <span v-else-if="detailData.roomType===1">正式房间</span>
                </div>
                <div class="detailRoomCont"><span style="color:#409EFF">施工人员: </span>{{detailData.installerUserName}}</div>
                <div class="detailRoomCont"><span style="color:#409EFF">创建时间: </span>{{detailData.createTime}}</div>
                <div style="padding:0 20px;margin:10px 0;float:left;width:100%;"><span style="color:#409EFF">房间描述: </span>{{detailData.roomDesc}}</div>
            </div>
            <div style="margin:10px 20px;">
                <div style="margin-bottom:10px;"><span style="color:#409EFF">房间设备：</span></div>
                <div style="overflow:hidden;">
                    <div v-for="(item,index) in deviceList" v-bind:key="index" style="float:left;width:31%;margin-right:1%;margin-bottom:5px;">
                        {{item.deviceRemark}}（<span v-if="item.deviceStatus==0" style="color:red">离线</span><span v-else-if="item.deviceStatus==1" style="color:green">在线</span><span v-else-if="item.deviceStatus==9" style="color:gray">未安装</span>）
                    </div>
                </div>
            </div>
            <div style="margin:10px 20px;">
                <div style="margin-bottom:10px;"><span style="color:#409EFF">房间二维码：</span><a style="color:#276EF1" @click="handleDownloadQrIMg(detailData.rqImageBase64)">保存二维码</a></div>
                <img style="width:200px" :src="'data:image/png;base64,'+detailData.rqImageBase64" alt="">

            </div>
        </el-dialog>
    </div>
</template>

<script>
    import Mypage from '../../../components/MyPagination.vue'
    import {getHotelCount,getRoomCount,getDeviceCount,getOnlineCount,getOutlineCount,activityCount,getCharts,roomActivityCount} from './count'
    import {RoomDetail,RoomDevice} from '../../Room/manage'
    export default {
        name: "SmartHomeCount",
        components: {
            Mypage
        },
        data() {
            return {
                total:10,
                limit: 5,
                page: 1,
                roomTotal:10,
                endDate:'', //结束时间
                startDate:'', //开始时间
                smartHomeName:'', //酒店名称
                chartHotelName:'', //查询折线图的酒店名称
                smartHomeId:'', //酒店ID
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                }, //快捷查询
                dateScope: '', //查询日期范围
                charts: '',
                xaxisData:[],
                opinionData: [],
                hotelCount:{count:0,clm:0}, //酒店统计
                roomCount:{count:0,clm:0}, //酒店房间统计
                deviceCount:{count:0,clm:0}, //酒店设备统计
                onlineCount:{count:0,clm:0}, //在线设备统计
                outlineCount:{count:0,clm:0}, //离线设备统计
                hotelActivityCount:[], //酒店设备活跃度数组
                roomActivityData:[], //房间设备活跃度数组
                detailVisible:false,//详情模态框
                detailData:{},// 房间详情
                deviceList:[],//房间设备列表
            }
        },
        methods: {
            //获取5个统计数据
            getCountData(){
                //获取项目总数
                getHotelCount(this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.hotelCount=result['data'].data
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
                //获取房间总数
                getRoomCount(this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.roomCount=result['data'].data
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
                //获取酒店设备总数
                getDeviceCount(this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.deviceCount=result['data'].data
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
                //获取在线设备总数
                getOnlineCount(this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.onlineCount=result['data'].data
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
                //获取离线设备总数
                getOutlineCount(this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.outlineCount=result['data'].data
                    }else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            //选择查询日期范围
            selDate(){
                if(this.dateScope!=null){
                    this.startDate=this.dateScope[0]
                    this.endDate=this.dateScope[1]
                }
            },
            //酒店设备活跃度查询
            hotelActivity(){
                activityCount(this.startDate,this.endDate,this.smartHomeName,this.limit,this.page,this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.hotelActivityCount = result['data'].data.records
                        this.smartHomeId=result['data'].data.records[0].smartHomeId
                        this.chartHotelName=result['data'].data.records[0].smartHomeName
                        this.total=parseInt(result['data'].data.total)
                        this.drawLine(this.smartHomeId)
                        this.roomActivity(this.smartHomeId)
                    } else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            //点击查询酒店折线图
            selHotel(row){
                console.log('row',row)
                this.chartHotelName=row.smartHomeName
                this.drawLine(row.smartHomeId)
                this.roomActivity(row.smartHomeId)
            },
            //绘制折线图
            drawLine(smartHomeId) {
                getCharts(this.startDate,this.endDate,smartHomeId,this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        if(result["data"].data.xaxisData!=[]){
                            this.xaxisData=result["data"].data.xaxisData
                        }
                        if(result["data"].data.yaxisData){
                            this.opinionData=result["data"].data.yaxisData
                        }
                        this.charts = this.$echarts.init(document.getElementById('main'))
                        this.charts.setOption({
                            tooltip: {
                                trigger: 'axis'
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            toolbox: {
                                feature: {
                                    saveAsImage: {}
                                }
                            },
                            xAxis: {
                                type: 'category',
                                boundaryGap: false,
                                data: this.xaxisData
                            },
                            yAxis: {
                                type: 'value'
                            },
                            series: [{
                                name: '日活量统计',
                                type: 'line',
                                smooth: true,
                                stack: '总量',
                                data: this.opinionData
                            }]
                        })
                    } else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })

            },
            //房间设备活跃度查询
            roomActivity(smartHomeId){
                console.log('hitel',smartHomeId)
                roomActivityCount(this.startDate,this.endDate,smartHomeId,this.limit,this.page,this.$cookies.get("access_token")).then((result) => {
                    if (result["data"].code == 0) {
                        this.roomActivityData = result['data'].data.records
                        this.roomTotal=parseInt(result['data'].data.total)
                    } else {
                        this.$message({
                            message: result["data"].message,
                            type: 'error'
                        });
                    }
                })
            },
            //打开详情弹框
            openDetail(row){
                this.detailVisible = true
                // this.roomId=row.roomId
                //房间详情
                RoomDetail(row.roomId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        this.detailData=result["data"]["data"]
                        // this.handleDownloadQrIMg(this.detailData.rqImageBase64)
                    }

                })
                //    房间设备
                RoomDevice(row.roomId,this.$cookies.get("access_token")).then((result)=>{
                    if (result["data"].code == 0) {
                        this.deviceList=result["data"]["data"]
                    }
                })

            },
            //下载小程序二维码
            handleDownloadQrIMg(qrBase64) {
                // 这里是获取到的图片base64编码,这里只是个例子哈，要自行编码图片替换这里才能测试看到效果
                const imgUrl = `data:image/png;base64,${qrBase64}`
                // 如果浏览器支持msSaveOrOpenBlob方法（也就是使用IE浏览器的时候），那么调用该方法去下载图片
                if (window.navigator.msSaveOrOpenBlob) {
                    const bstr = atob(imgUrl.split(',')[1])
                    let n = bstr.length
                    const u8arr = new Uint8Array(n)
                    while (n--) {
                        u8arr[n] = bstr.charCodeAt(n)
                    }
                    const blob = new Blob([u8arr])
                    window.navigator.msSaveOrOpenBlob(blob, 'chart-download' + '.' + 'png')
                } else {
                    // 这里就按照chrome等新版浏览器来处理
                    const a = document.createElement('a')
                    a.href = imgUrl
                    a.setAttribute('download', 'chart-download')
                    a.click()
                }
            },
            handleClose(done) {
                done();
            },
            //    分页
            changePage(num){
                this.page=num;
                this.hotelActivity()
            },
        },
        //调⽤
        mounted() {
            this.getCountData()
            this.hotelActivity()
            // this.$nextTick(function() {
            //     this.drawLine()
            // })
        }
    }
</script>
<style scoped>
    .el-input {
        width: 120px;
        /*margin: 0 5px;*/
    }
    ::v-deep .el-table .el-table__cell {
        padding:5px 0
    }
    .detailRoomCont {
        float:left;
        width:40%;
        padding:0 20px;
        margin:10px 0;
    }
    ::v-deep .el-date-editor .el-range__close-icon {
        width:0;
    }

    ::v-deep .el-range-editor.el-input__inner {
        width:221px;
    }

</style>