import request from '../../../utils/request'

//查询项目总数
export function getHotelCount(token) {
    return request({
        url:"/hotel/smartHomeStatistic/count/smartHome",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//查询房间统计
export function getRoomCount(token) {
    return request({
        url:"/hotel/smartHomeStatistic/count/user",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//查询设备统计
export function getDeviceCount(token) {
    return request({
        url:"/hotel/smartHomeStatistic/count/userDevice",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//查询在线设备统计
export function getOnlineCount(token) {
    return request({
        url:"/hotel/smartHomeStatistic/count/userOnlineDevice",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//查询离线设备统计
export function getOutlineCount(token) {
    return request({
        url:"/hotel/smartHomeStatistic/count/userOfflineDevice",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
    })
}
//酒店设备活跃度查询
export function activityCount(startDate,endDate,smartHomeName,limit,page,token) {
    return request({
        url:"/hotel/smartHomeStatistic/activity/smartHome/count",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            startDate:startDate,
            endDate:endDate,
            smartHomeName:smartHomeName,
            limit:limit,
            page:page,
        }
    })
}
//酒店设备活跃度折线图
export function getCharts(startDate,endDate,smartHomeId,token) {
    return request({
        url:"/hotel/smartHomeStatistic/activity/smartHomeDevice/chart",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            startDate:startDate,
            endDate:endDate,
            smartHomeId:smartHomeId
        }
    })
}
//房间设备活跃度
export function roomActivityCount(startDate,endDate,smartHomeId,limit,page,token) {
    return request({
        url:"/hotel/smartHomeStatistic/activity/smartHomeDevice/count",
        method:"get",
        headers:{
            'Authorization':'Bearer ' + token
        },
        params:{
            startDate:startDate,
            endDate:endDate,
            smartHomeId:smartHomeId,
            limit:limit,
            page:page,
        }
    })
}